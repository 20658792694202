






























































































































import Vue, { PropType } from 'vue';
import CustomerOrderDetailDto from '@/models/CustomerOrderDetailDto';
import ToastMessage from '@/models/ToastMessage';
import OwnerOrderService from '@/services/owner/OwnerOrderService';

export default Vue.extend({
  props: {
    order: {
      type: Object as PropType<CustomerOrderDetailDto>,
    },
    showTax: {
      type: Boolean,
      default: true,
    },
    showHostInfo: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    regularTax(): number {
      return parseFloat(this.order.regularTax);
    },
    reducedTax(): number {
      return parseFloat(this.order.reducedTax);
    },
    regularTaxRate(): number {
      return parseFloat(this.order.regularTaxRate);
    },
    reducedTaxRate(): number {
      return parseFloat(this.order.reducedTaxRate);
    },
    showVoucherInfo(): boolean {
      return this.order.voucher && parseFloat(this.order.voucherDiscount) > 0;
    },
    showPlatformVoucherHostInfo(): boolean {
      return this.showHostInfo && this.order.voucher.voucherScopeType == 'MyPlayrooms';
    },
  },
  methods: {
    formatCurrency(item: number) {
      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(item);
    },

    handleDownloadInvoice() {
      this.$store.commit(
        'addToastMessage',
        new ToastMessage(this.$t('components.hostOrderDetailButtons.downloadMsg').toString(), 'bg-success')
      );

      OwnerOrderService.getInvoice(this.order.orderId).then((res) => {
        var link = document.createElement('a');
        link.innerHTML = 'Download PDF file';
        link.download = 'Rechnungsvorlage.pdf';
        link.href = 'data:application/octet-stream;base64,' + res.value;
        link.click();
      });
    },
  },
});
