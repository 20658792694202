























































import Vue, { PropType, VueConstructor } from 'vue';
import CustomerOrderDetailDto from '@/models/CustomerOrderDetailDto';
import CancellationInformation from '@/components/orderDetailParts/CancellationInformation.vue';
import FormaterMixin from '@/mixins/FormaterMixin.vue';

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormaterMixin>>).extend({
  components: { CancellationInformation },
  mixins: [FormaterMixin],
  props: {
    order: {
      type: Object as PropType<CustomerOrderDetailDto>,
    },
  },
  computed: {
    prepayment(): number {
      return parseFloat(this.order.prepayment);
    },
    deposit(): number {
      return parseFloat(this.order.deposit);
    },
  },
});
