
























import Vue, { PropType } from 'vue';
import CustomerOrderDetailDto from '@/models/CustomerOrderDetailDto';
import { de } from 'date-fns/locale';
import toDate from 'date-fns-tz/toDate';
import { parseISO, format } from 'date-fns';

export default Vue.extend({
  props: {
    order: {
      type: Object as PropType<CustomerOrderDetailDto>,
    },
  },
  computed: {
    isFree(): boolean {
      return this.order.cancellation.percentage == 0;
    },
    isImpossible(): boolean {
      const startDate = parseISO(this.order.start);
      return startDate < new Date();
    },
    has100Fee(): boolean {
      return !this.isFree && !this.isImpossible && this.order.cancellation.percentage == 100;
    },
    hasFee(): boolean {
      return !this.isFree && !this.isImpossible && this.order.cancellation.percentage != 100;
    },
    validUntil(): string {
      if (this.order.cancellation.validUntil != null) {
        const date = toDate(this.order.cancellation.validUntil);
        return format(date, 'P | p', { locale: de });
      } else {
        return '';
      }
    },
  },
});
