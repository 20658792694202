import { parse } from 'date-fns';

export class OrderedPlayroomAdditionDto {
  id!: string;
  playroomAdditionId!: string;
  additionName!: string;
  additionalRent!: number;
  count!: number;
  hasChecked!: boolean;
  bookingType!: string;
  priceForHourly!: number;
  priceForOvernight!: number;
  isPricePerPiece!: number;
  isPricePerTime!: number;
}

export enum VoucherDiscountType {
  Unknown,
  Percentage,
  Amount,
}

export enum VoucherScopeType {
  Unknown,
  MyPlayrooms,
  Company,
}

export class Voucher {
  minimalOrderAmount!: number;
  id!: string;
  code!: string;
  amount!: number | null;
  percentage!: number | null;
  voucherDiscountType!: VoucherDiscountType;
  voucherScopeType!: string;
}

export class PlayroomOrderEditViewModel {
  allowHourBooking!: boolean;
  allowOvernightStay!: boolean;
}

export class DiscountExtraCharge {
  constructor() {
    this.optionName = '';
    this.option = 'Discount';
    this.comment = '';
    this.amount = 0;
  }
  id!: string;
  optionName!: string;
  option!: string;
  amount!: number;
  comment!: string;
}

export class CancellationViewModel {
  percentage!: number;
  validUntil!: Date | null;
}

export class OrderEvents {
  id!: string;
  date!: string;
  type!: string;
}

export class LineItem {
  type!: string;
  amount!: number;
  price!: number;
}

export default class CustomerOrderDetailDto {
  id!: string;
  amount!: number;
  baseRent!: number;
  orderCreatedAt!: Date;
  orderedPlayroomAdditions!: OrderedPlayroomAdditionDto[];
  orderedDiscountExtraCharge!: DiscountExtraCharge[];
  playroomCity!: string;
  playroomCountry!: string;
  playroomId!: string;
  playroomName!: string;
  playroomState!: string;
  playroomHouseNumber!: string;
  playroomStreet!: string;
  playroomZipCode!: string;
  playroomLatitude!: number;
  playroomLongitude!: number;
  start!: string;
  end!: string;
  hiddenAddress!: boolean;
  ownerName!: string;
  playroomPropertyType!: string;
  playroomRoomCount!: string;
  playroomFeatures!: string[];
  guestCount!: number;
  playroomAddressRadius!: number;
  cancellation!: CancellationViewModel;
  prepayment!: string;
  deposit!: string;
  acceptCash!: boolean;
  acceptsCreditCard!: boolean;
  acceptsInvoice!: boolean;
  acceptsPaypal!: boolean;
  orderId!: string;
  personalNotice!: string;
  orderEvents!: OrderEvents[];
  lineItems!: LineItem[];
  status!: string;
  customerName!: string;
  acceptPrivacyPolicy = false;
  acceptBusinessTerms = false;
  acceptHouseRules = false;
  customerId!: string;
  bookingType!: string;
  isDirty!: boolean;
  anyError!: boolean;
  playroom!: PlayroomOrderEditViewModel;
  regularTax!: string;
  reducedTax!: string;
  reducedTaxRate!: string;
  regularTaxRate!: string;
  ownerId!: string;
  customerEmail!: string;
  customerPhone!: string;
  ownerEmail!: string;
  ownerPhone!: string;
  houseRulesFileLink!: string;
  businessTermsFileLink!: string;
  privacyPolicyFileLink!: string;
  guestIsDeleted!: boolean;
  startTimeInUTC!: string;
  orderNotes!: string;
  guestNotes!: string;
  isNewGuest!: boolean;
  totalAmount!: string;
  voucherDiscount!: string;
  voucher!: Voucher;
  sourceType!: string;
  sourceId!: string;

  get startDate(): Date {
    return parse(this.start, 'P HH:mm', new Date());
  }

  get endDate(): Date {
    return parse(this.end, 'P HH:mm', new Date());
  }

  get shortOrderId(): string {
    return this.orderId.split('-')[0];
  }
}
